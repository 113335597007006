import React from "react"
import AboutJob from "components/aboutJob"
import "./style.scss"

export default function AboutSection({
  data: {
    pageName,
    paragraphMe,
    paragraphTechno,
    paragraphCompanies,
    jobExperiences,
  },
}) {
  return (
    <div className="c-section c-about" id="about">
      <div className="container">
        <div className="container-tab">
          <h2 className="ft-h c-section__title">{pageName}</h2>
        </div>

        <div
          className="c-about__paragraph c-about__paragraph--me ft-h"
          dangerouslySetInnerHTML={{ __html: paragraphMe.paragraphMe }}
        />

        <div
          className="c-about__paragraph c-about__paragraph--techno ft-h"
          dangerouslySetInnerHTML={{ __html: paragraphTechno.paragraphTechno }}
        />

        <div
          className="c-about__paragraph c-about__paragraph--jobs container-tab ft-h"
          dangerouslySetInnerHTML={{
            __html: paragraphCompanies.paragraphCompanies,
          }}
        />

        {jobExperiences &&
          jobExperiences.map(jobExperience => (
            <AboutJob key={jobExperience.name} data={jobExperience} />
          ))}
      </div>
    </div>
  )
}
