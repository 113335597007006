import React, { Component } from "react"
import "./style.scss"
import { ModalConsumer } from "components/modalContainer"
import ModalProject from "components/modalContainer/modalProject"
import WhenVisible from "utils/WhenVisible"
import imgPlaceholder from "static/img/lazyload.png"
import { withTrans } from "../../i18n/withTrans"

class Project extends Component {
  constructor() {
    super()
    this.project = React.createRef()
  }
  componentDidMount() {
    const { data: project } = this.props

    let illustration = project.illustration ? project.illustration.fluid : null

    WhenVisible.register(this.project, element => {
      // TweenMax.fromTo(
      //   element,
      //   0.3,
      //   { opacity: 0, y: 20 },
      //   { opacity: 1, y: 0, delay: 0.1 }
      // )
      element.classList.add('on');

      element.querySelector("img").src = illustration && illustration.src
      element.querySelector(".srcsetwebp").srcset =
        illustration && illustration.srcSetWebp
      element.querySelector(".srcsetwebp").srcset =
        illustration && illustration.srcSet
    })
  }

  openProjectModal = showModal => {
    const { data: project } = this.props

    window.gtag &&
      window.gtag("event", "page_view", {
        project: project.name,
      })
    showModal(ModalProject, { isOpen: true, data: project })
  }
  render() {
    const { data: project, t } = this.props

    let illustration = project.illustration ? project.illustration.fluid : null

    return (
      <ModalConsumer>
        {({ showModal }) => (
          <div
            ref={c => (this.project = c)}
            className="c-project"
            onClick={() => this.openProjectModal(showModal)}
            onKeyPress={() =>
              showModal(ModalProject, { isOpen: true, data: project })
            }
            role="link"
            tabIndex="0"
          >
            {project.year && (
              <span className="c-project__year ft-grey">{project.year}</span>
            )}
            <div className="c-project__thumb">
              {illustration ? (
                <picture>
                  <source
                    className="srcsetwebp"
                    srcSet={imgPlaceholder}
                    type="image/webp"
                  />
                  <source
                    className="srcset"
                    srcSet={imgPlaceholder}
                    type="image/jpeg"
                  />
                  <img
                    src={imgPlaceholder}
                    alt={`${project.name}-${project.year}`}
                  />
                </picture>
              ) : (
                <span className="c-project__thumb__placeholder"></span>
              )}
              <p className="c-project__thumb__more">{t("seeMore")}</p>
            </div>

            <div className="c-project__title">
              <h2 className="ft-h ft-title-large">{project.name}</h2>
            </div>
          </div>
        )}
      </ModalConsumer>
    )
  }
}

export default withTrans(Project)
