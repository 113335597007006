let map = new Map()

class Rectangle {
  constructor(x = 0, y = 0, width = 1, height = 1, left = 0, top = 0) {
    this.set({ x, y, width, height, left, top })
  }

  set({ x = 0, y = 0, width = 1, height = 1, left = 0, top = 0 } = {}) {
    if (typeof x === "undefined") {
      x = left
    }
    if (typeof y === "undefined") {
      y = top - height
    }

    this.x = x
    this.y = y
    this.width = width
    this.height = height

    return this
  }

  get area() {
    let { width, height } = this
    return width * height
  }

  intersection(other) {
    let x = Math.max(this.x, other.x)
    let y = Math.max(this.y, other.y)
    let maxX = Math.min(this.x + this.width, other.x + other.width)
    let maxY = Math.min(this.y + this.height, other.y + other.height)

    if (maxX < x || maxY < y) return new Rectangle(0, 0, 0, 0)

    return new Rectangle(x, y, maxX - x, maxY - y)
  }
}

let windowRectangle = new Rectangle(0, 0, 0, 0)

const init = window => {
  const update = () => {
    windowRectangle.width = window.innerWidth
    windowRectangle.height = window.innerHeight
  }

  window.addEventListener("resize", update)
  update()
}

typeof window !== "undefined" && init(window)

const register = (div, callback = {}) => {
  map.set(div, callback)
}

const registerAll = (divs, callback) => {
  for (let div of divs) register(div, callback)
}

const watch = () => {
  for (let [div, callback] of map.entries()) {
    let rectangle = new Rectangle().set(div.getBoundingClientRect())
    let visibleRectangle = windowRectangle.intersection(rectangle)

    if (visibleRectangle.area > 0) {
      if (callback) callback(div)

      map.delete(div)
    }
  }
}

typeof requestAnimationFrame !== "undefined" &&
  requestAnimationFrame(function loop() {
    watch()
    requestAnimationFrame(loop)
  })

const WhenVisible = {
  map,
  register,
  registerAll,
}

// global... or not
typeof window !== "undefined" && Object.assign(window, { WhenVisible })

export default WhenVisible
