import React, { Component } from "react"
import Layout from "components/layout"
import SEO from "../seo"
import "styles/index.scss"
import Project from "components/project"
import AboutSection from "components/aboutSection"
import "./style.scss"
import CircleType from "circletype"

export default class IndexPage extends Component {
  componentDidMount() {
    new CircleType(document.querySelector(".job-search"));
  }

  render() {
    if (!this.props.data.allContentfulHomePage) {
      if (typeof window !== "undefined") {
        window.location = "/"
      }
      return null
    }

    const {
      allContentfulHomePage: { pageContent: homeContent },
      allContentfulAboutPage: { pageContent: aboutContent },
    } = this.props.data

    let contentH = homeContent[0] ? homeContent[0].node : {}
    let projects = contentH.homeProjects || []
    let headText = contentH.headText || null

    let contentA = aboutContent[0] ? aboutContent[0].node : {}

    return (
      <Layout location={this.props.location}>
        <SEO title="Stephen Richard" />
        <div className="c-section c-section-header-home">
          <div className="container">
            {headText && (
              <h1
                className="ft-h ft-h1"
                dangerouslySetInnerHTML={{ __html: headText }}
              ></h1>
            )}
          </div>
        </div>

        <a href="#footer" className="job-search ft-h">
          I am looking for a job in Tokyo.
        </a>

        <div className="c-section c-section-projects">
          <div className="container container--offset">
            {projects.map(project => {
              return <Project key={project.id} data={project} />
            })}
          </div>
        </div>

        <AboutSection data={contentA} />
      </Layout>
    )
  }
}
