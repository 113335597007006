import React from "react"
import "./style.scss"

export default function AboutJob({
  data: { name, location, jobDuration, jobTitle, url },
}) {
  return (
    <a className="c-job" href={url} target="_blank" rel="noopener noreferrer">
      <div className="c-job__misc">
        <p className="ft-grey">{jobDuration}</p>
        <span
          className="ft-h c-job__location"
          role="img"
          aria-label={`job location ${location}`}
        >
          {location}
        </span>
      </div>
      <div>
        <span className="ft-h ft-h1 c-job__company">{name}</span>
        <p className="ft-h ft-grey">{jobTitle}</p>
      </div>
      <div className="c-job__link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          data-credit="Icon by useiconic.com from the Noun Project"
        >
          <polygon points="9.75 .688 4.094 6.344 15.407 6.344 .563 21.188 4.813 25.438 19.657 10.594 19.657 21.907 25.313 16.251 25.313 .688" />
        </svg>
      </div>
    </a>
  )
}
