import React, { Component } from "react"
import { ModalContainer } from "components/modalContainer"
import "./style.scss"
import { withTrans } from "../../../i18n/withTrans"

class ModalProject extends Component {
  componentDidMount() {
    this.modalContent = this.refs.projectDetail.closest(".c-modal__content")
    this.projectHead = this.modalContent.querySelector(".c-modal__head")

    this.modalContent.addEventListener("scroll", this.scrollInsideModal)
  }

  scrollInsideModal = e => {
    let { height: headHeight } = this.projectHead.getBoundingClientRect()

    if (e.target.scrollTop > headHeight) {
      if (!this.projectHead.classList.contains("c-modal__head--scrolled"))
        this.projectHead.classList.add("c-modal__head--scrolled")
    } else {
      if (this.projectHead.classList.contains("c-modal__head--scrolled"))
        this.projectHead.classList.remove("c-modal__head--scrolled")
    }
  }

  componentWillUnmount() {
    this.modalContent.removeEventListener("scroll", this.scrollInsideModal)
  }

  render() {
    const { onRequestClose, t, ...otherProps } = this.props
    const { data } = otherProps
    const content = data.content ? data.content.json : {}
    const medias = data.medias ? data.medias : null

    return (
      <ModalContainer
        onRequestClose={onRequestClose}
        className="c-modal-project"
        headContent={
          <div className="c-modal-project__head-data">
            <span className="ft-grey">{data.year}</span>
            <p className="ft-h">{data.name}</p>
          </div>
        }
      >
        <div className="c-project-detail" ref="projectDetail">
          <p className="ft-h ft-h1">{data.name}</p>
          <span className="ft-h c-project-detail__heading">
            {data.jobTitle}{" "}
            {data.company && <p className="ft-grey"> - {data.company.name}</p>}
          </span>
          {data.projectUrl && (
            <a
              href={data.projectUrl}
              alt={data.name}
              target="_blank"
              rel="noopener noreferrer"
              className="c-project__url link ft-dark-grey"
            >
              <p>{t("viewProject")}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                data-credit="Icon by useiconic.com from the Noun Project"
              >
                <polygon points="9.75 .688 4.094 6.344 15.407 6.344 .563 21.188 4.813 25.438 19.657 10.594 19.657 21.907 25.313 16.251 25.313 .688" />
              </svg>
            </a>
          )}
          <div className="c-project-detail__content">
            {content.content &&
              content.content.map((bloc, index) => {
                if (bloc.nodeType === "paragraph") {
                  let blocContent = bloc.content[0]
                  if (blocContent) {
                    return (
                      <div
                        className="paragraph"
                        key={`blocContent-${index}`}
                        dangerouslySetInnerHTML={{ __html: blocContent.value }}
                      />
                    )
                  }
                }

                return null
              })}
          </div>
          <div className="c-project-detail__medias">
            {medias &&
              medias.map(media => (
                <div
                  key={media.title}
                  className={media.title.match(/(mobile)/i) ? "is-mobile" : ""}
                >
                  <div className="picture">
                    <picture>
                      <source
                        srcSet={media.fluid.srcSetWebp}
                        type="image/webp"
                      />
                      <source srcSet={media.fluid.srcSet} type="image/jpeg" />
                      <img src={media.fluid.src} alt={media.title} />
                    </picture>
                  </div>
                  <p className="ft-grey">
                    {media.title} - {data.year}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </ModalContainer>
    )
  }
}

export default withTrans(ModalProject)
